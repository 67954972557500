<template>
    <div style="display: flex">
      <el-container>
        <CommonTree
          :defaultProps="defaultProps"
          :isShowdig="false"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          :treeData="treeData"
          :treeExpand="false"
          style="font-size: 13px"
          @getNodeClick="handleNodeClick"
          @getTreeAdd="getTreeAdd"
          @getTreeDelete="getTreeDelete"
          @getTreeEdit="getTreeEdit"
        />
        <el-main>
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="题库管理"
            @head-add="opDialog(4)"
          >
          </head-layout>
  
          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal"
            :gridRowBtn="gridRowBtn"
            :page="page"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
            @grid-see="SeeData"
            @grid-edit="rowEdit"
            @grid-romve="rowDel"
          >
            <template slot="sourceType" slot-scope="{ row }">
              {{ gettype(row.sourceType) }}
            </template>
          </grid-layout>
        </el-main>
      </el-container>
  
      <!-- <el-container> -->
  
      <!-- </el-container> -->
  
      <basic-container>
        <!-- 新建目录弹框 -->
        <el-dialog :visible.sync="dialogVisible1" title="提示" @close="Addclose">
          <el-form :model="form">
            <span>选择父目录：</span> <span>{{ treeWay2 }}</span>
  
            <br />
            <span style="color: red">*</span><span>目录名称：</span>
            <br />
            <el-form-item>
              <el-input
                v-model="addTreeData.name"
                autocomplete="off"
                placeholder="请输入目录"
              ></el-input>
            </el-form-item>
            <span>请选择是否为末级目录：</span>
            <el-select
              filterable
              v-model="addTreeData.type"
              placeholder="请选择是否为末级目录"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button style="margin: 0px 20px" @click="dialogVisible1 = false">{{
              $t("cancelText")
            }}</el-button>
            <el-button
              style="margin: 0px 20px"
              type="primary"
              @click="MAddTree"
              >{{ $t("submitText") }}</el-button
            >
          </div>
        </el-dialog>
  
        <!-- 删除目录弹框 和 删除数据对象 -->
  
        <el-dialog :visible.sync="dialogVisible2" title="提示">
          <div>确定要删除当前目录？</div>
          <div slot="footer" class="dialog-footer">
            <el-button style="margin: 0px 20px" @click="dialogVisible2 = false">{{
              $t("cancelText")
            }}</el-button>
            <el-button
              style="margin: 0px 20px"
              type="primary"
              @click="Mdeltree"
              >{{ $t("submitText") }}</el-button
            >
          </div>
        </el-dialog>
  
        <el-dialog
          :title="$t('cip.dc.dataobject.title.tip')"
          :visible.sync="dialogVisible3"
        >
          <div>{{ $t("cip.dc.dataobject.msg.delmsg3") }}</div>
          <div slot="footer" class="dialog-footer">
            <el-button style="margin: 0px 20px" @click="dialogVisible3 = false">{{
              $t("cancelText")
            }}</el-button>
            <el-button
              style="margin: 0px 20px"
              type="primary"
              @click="MDelObjectList"
              >{{ $t("submitText") }}</el-button
            >
          </div>
        </el-dialog>
  
        <!-- 新建数据对象弹框 -->
  
        <el-dialog
          :visible.sync="dialogVisible4"
          width="65%"
          @close="Addclose2"
          @open="Addopen2"
        >
          <el-form :model="listAdd" label-position="right" label-width="100px">
            <el-form-item
              :label="$t('cip.dc.dataobject.field.tree')"
              :label-width="formLabelWidth"
            >
              {{ treeWay }}
            </el-form-item>
            <el-form-item
              :label="$t('cip.dc.dataobject.field.type')"
              :label-width="formLabelWidth"
            >
              <el-select
                filterable
                v-model="listAdd.sourceType"
                :placeholder="$t('cip.dc.dataobject.field.type')"
                @change="ChooseType($event)"
              >
                <el-option
                  v-for="item in TypeOp"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
  
            <el-form-item
              :label="$t('cip.dc.dataobject.field.code')"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="listAdd.code"
                :placeholder="$t('cip.dc.dataobject.msg.p2')"
                autocomplete="off"
              ></el-input>
            </el-form-item>
  
            <div v-if="listAdd.sourceType == 1">
              <el-form-item
                :label="$t('cip.dc.dataobject.field.databank')"
                :label-width="formLabelWidth"
              >
                <el-select
                  filterable
                  v-model="listAdd.metadataInfoDTO.metadataSourceId"
                  :placeholder="$t('cip.dc.dataobject.field.databank')"
                  @change="ChooseSJKOP($event)"
                >
                  <!-- SJKOP -->
                  <el-option
                    v-for="item in SJKOP"
                    :key="item.id"
                    :label="item.sourceName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
  
              <el-form-item
                :label="$t('cip.dc.dataobject.field.tableName')"
                :label-width="formLabelWidth"
              >
                <el-select
                  filterable
                  v-model="listAdd.metadataInfoDTO.metadataTableId"
                  :placeholder="$t('cip.dc.dataobject.field.tableName')"
                  @change="ChooseSBMOP($event)"
                >
                  <el-option
                    v-for="item in SBMOP"
                    :key="item.id"
                    :label="toStr(item)"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
  
              <el-form-item
                :label="$t('cip.dc.dataobject.field.saveField')"
                :label-width="formLabelWidth"
              >
                <el-table
                  ref="SBZDbcTable"
                  :data="SBZDbc"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldName')"
                    prop="columnName"
                  >
                  </el-table-column>
  
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldComments')"
                    prop="columnComment"
                  >
                  </el-table-column>
                </el-table>
              </el-form-item>
  
              <el-form-item
                :label="$t('cip.dc.dataobject.field.ConditionField')"
                :label-width="formLabelWidth"
              >
                <el-table
                  ref="SBZDtjTable"
                  :data="SBZDtj"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldName')"
                    prop="columnName"
                    width="180"
                  >
                  </el-table-column>
  
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldComments')"
                    prop="columnComment"
                  >
                  </el-table-column>
  
                  <!-- dataType datetime  timestam-->
  
                  <el-table-column label="类型" width="160">
                    <template slot-scope="scope">
                      <el-select
                        v-if="
                          scope.row.dataType == 'datetime' ||
                          scope.row.dataType == 'timestam'
                        "
                        v-model="scope.row.TimeType"
                        placeholder="请选择"
                        @change="changeTimeType($event, scope.row)"
                      >
                        <el-option label="固定时间范围" value="1"> </el-option>
                        <el-option label="动态时间范围" value="2"> </el-option>
                      </el-select>
  
                      <el-select
                        filterable
                        v-if="
                          scope.row.dataType != 'datetime' &&
                          scope.row.dataType != 'timestam'
                        "
                        v-model="scope.row.QueryType"
                        placeholder="请选择"
                        @change="changeQueryType($event, scope.row)"
                      >
                        <el-option
                          v-for="(item, index) in queryTypeList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
  
                  <!-- <el-table-column prop="dataDefault" :label="$t('cip.dc.dataobject.field.default')" width="180">
                  </el-table-column> -->
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.enterValue')"
                    prop="BCinput"
                    width="360"
                  >
                    <template slot-scope="scope">
                      <!-- <el-input v-model="scope.row.BCinput" size="small"> </el-input> -->
                      <div
                        v-if="scope.row.QueryType && scope.row.QueryType !== '-1'"
                      >
                        <div v-if="scope.row.QueryType == '3'">
                          <el-input
                            v-model="scope.row.startValue"
                            placeholder="开始"
                            style="width: 50%"
                          ></el-input>
                          <el-input
                            v-model="scope.row.endValue"
                            placeholder="结束"
                            style="width: 50%"
                          ></el-input>
                        </div>
                        <el-input
                          v-if="scope.row.QueryType !== '3'"
                          v-model="scope.row.BCinput"
                          placeholder="请输入值"
                        ></el-input>
                      </div>
                      <div v-else>
                        <el-date-picker
                          v-if="scope.row.TimeType == '1'"
                          v-model="scope.row.gdtime"
                          end-placeholder="结束日期"
                          range-separator="至"
                          start-placeholder="开始日期"
                          style="width: 350px"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
  
                        <el-select
                          filterable
                          v-if="scope.row.TimeType == '2'"
                          v-model="scope.row.DTime"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in DtimeType"
                            :key="item.value_name"
                            :label="item.value_name"
                            :value="item.value_code"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
  
              <!-- 暂时隐藏 -->
              <el-form-item
                :label="$t('cip.dc.dataobject.field.comparisonField')"
                :label-width="formLabelWidth"
                style="display: none"
              >
                <el-table
                  ref="SBZDdbTable"
                  :data="SBZDdb"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldName')"
                    prop="columnName"
                  >
                  </el-table-column>
  
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldComments')"
                    prop="columnComment"
                  >
                  </el-table-column>
                </el-table>
              </el-form-item>
  
              <el-form-item label="排序字段" :label-width="formLabelWidth">
                <el-table
                  ref="pxTable"
                  :data="SBZDpx"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    prop="columnName"
                    :label="$t('AiSuanfa.detail.zh')"
                  >
                  </el-table-column>
                  <!-- <el-table-column
                prop="columnComment"
                :label="$t('AiSuanfa.detail.field')"
              >
              </el-table-column> -->
                  <el-table-column
                    prop="dataType"
                    :label="$t('AiSuanfa.detail.dataType')"
                  >
                  </el-table-column>
                  <el-table-column prop="dataType" label="排序">
                    <template slot-scope="scope">
                      <el-select
                        class="tableSclect"
                        v-model="scope.row.sortRule"
                        placeholder="请选择"
                      >
                        <el-option label="升序" value="0"> </el-option>
                        <el-option label="降序" value="1"> </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </div>
  
            <div v-if="listAdd.sourceType == 2">
              <el-form-item
                :label="$t('cip.dc.dataobject.field.Apiinterface')"
                :label-width="formLabelWidth"
              >
                <el-select
                  filterable
                  v-model="listAdd.metadataInfoDTO.apiId"
                  :placeholder="$t('cip.dc.dataobject.field.Apiinterface')"
                  @change="ChooseAPIOP($event)"
                >
                  <el-option
                    v-for="item in APIOP"
                    v-show="item.status == 2"
                    :key="item.id"
                    :disabled="item.status != 2"
                    :label="item.apiName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
  
              <el-form-item
                :label="$t('cip.dc.dataobject.field.saveField')"
                :label-width="formLabelWidth"
              >
                <el-table
                  ref="SBZDbcTable2"
                  :data="SBZDbc"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldName')"
                    prop="fieldName"
                  >
                  </el-table-column>
  
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldComments')"
                    prop="fieldComment"
                  >
                  </el-table-column>
                </el-table>
              </el-form-item>
  
              <el-form-item
                :label="$t('cip.dc.dataobject.field.ConditionField')"
                :label-width="formLabelWidth"
              >
                <el-table
                  ref="SBZDtjTable2"
                  :data="SBZDtj"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldName')"
                    prop="paramName"
                    width="180"
                  >
                  </el-table-column>
  
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldComments')"
                    prop="paramComment"
                  >
                  </el-table-column>
  
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.default')"
                    prop="dataDefault"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.enterValue')"
                    prop="BCinput"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.BCinput"
                        size="small"
                      ></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
  
              <!-- 暂时隐藏 -->
              <el-form-item
                :label="$t('cip.dc.dataobject.field.comparisonField')"
                :label-width="formLabelWidth"
                style="display: none"
              >
                <el-table
                  ref="SBZDdbTable2"
                  :data="SBZDdb"
                  border
                  size="small"
                  style="width: 100%"
                >
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldName')"
                    prop="fieldName"
                  >
                  </el-table-column>
  
                  <el-table-column
                    :label="$t('cip.dc.dataobject.field.fieldComments')"
                    prop="fieldComment"
                  >
                  </el-table-column>
                </el-table>
              </el-form-item>
            </div>
  
            <el-form-item
              :label="$t('cip.dc.dataobject.field.identification')"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="listAdd.label"
                :placeholder="$t('cip.dc.dataobject.msg.p3')"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('cip.dc.dataobject.field.remark')"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="listAdd.remark"
                :placeholder="$t('cip.dc.dataobject.msg.p4')"
                :rows="3"
                autocomplete="off"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button style="margin: 0px 20px" @click="dialogVisible4 = false">{{
              $t("cancelText")
            }}</el-button>
            <el-button
              style="margin: 0px 20px"
              type="primary"
              @click="AddObjTable"
              >{{ $t("submitText") }}</el-button
            >
          </div>
        </el-dialog>
  
        <el-drawer
          :title="$t('cip.dc.dataobject.title.tip2')"
          :visible.sync="drawer"
          :with-header="false"
          @close="closedrawer"
          size="60%"
        >
          <div v-if="seetype == 1">
            <el-table :data="tableData4" style="width: 100%" class="show_table">
              <el-table-column
                v-for="(item, index) in tableData4List"
                :key="index"
                :label="item.column_name"
                :prop="item.column_name"
              >
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :currentPage="currentPage2"
              :page-sizes="[10,50, 100]"
              :page-size="PageSize2"
              layout="total,sizes, prev, pager, next"
              :total="totalCount2"
              class="paging"
            >
            </el-pagination>
          </div>
  
          <el-table v-if="seetype == 2" :data="tableData4" style="width: 100%">
            <el-table-column
              v-for="(item, index) in tableData4List"
              :key="index"
              :label="item"
              :prop="item"
            >
            </el-table-column>
          </el-table>
        </el-drawer>
      </basic-container>
    </div>
  </template>
  
  <script>
  import {
    AddObjectList,
    DelObjectList,
    DelObjTree,
    getColumnsList,
    getDataApisList,
    getDataApisXQ,
    getDataOBJXQ,
    getDataTree,
    getDTime,
    getSourcesList2,
    getSourcesTableList2,
    ObjectList,
    SaveAddTree,
    seeData,
  } from "@/api/dataAcquisition/index";
  import { getPowerconsumption3 } from "@/api/Ai";
  import seeUrl from "@/api/Ai/seeurl";
  
  import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
  import CommonTree from "@/views/components/com_tree/index";
  import GridLayout from "@/views/components/layout/grid-layout";
  import HeadLayout from "@/views/components/layout/head-layout";
  
  export default {
    watch: {
      // filterText(val) {
      //   this.$refs.tree.filter(val);
      // }
    },
    components: {
      CommonTree,
      GridLayout,
      HeadLayout,
    },
    data() {
      return {
        totalCount2: 0,
        rowId: "",
        currentPage2: 1,
        PageSize2: 50,
        DtimeType: [],
        searchTitle: "name",
        DelLsitid: "",
        tableOptions: {
          customRowAddBtn: false,
          menuWidth: 300,
          column: [
            {
              label: "题目",
              prop: "code",
              cell: true,
            },
            {
              label: "选项",
              prop: "label",
              cell: true,
            },
            {
              label: "答案",
              prop: "sourceType",
              cell: true,
              slot: true,
            },
            
          ],
        },
        tableData4: [],
        seetype: "",
        tableData4List: [],
        filterText: "",
        dialogVisible1: false,
        dialogVisible2: false,
        dialogVisible3: false,
        dialogVisible4: false,
        AddTitle: "新建",
        addTreeData: {
          parentId: "0",
          name: "",
          type: "0",
        },
        ObjParms: {
          query: {
            current: 1,
            size: 20,
          },
          dataObjectTypeId: null,
        },
  
        ObjParms2: {
          query: {
            current: 1,
            size: 10,
          },
          modelId: null,
        },
        ObjTreeid: "", //删除树id
        TypeOp: [
          {
            label: "数据库",
            value: 1,
          },
          {
            label: "API接口",
            value: 2,
          },
        ],
        listAdd: {
          modelId: "",
          code: "",
          label: "",
          id: "", //主键ID 修改时传入
          remark: "", //备注
          sourceType: null, //类型 1-数据库 2-api
          dataObjectTypeId: "", //数据对象类型id
          //元数据详情
          metadataInfoDTO: {
            apiId: "", //api类型使用
            metadataSourceId: "", // 数据库id 数据库类型使用
            metadataTableId: "", //表id数据库类型使用
            saveFields: [],
            //  {
            //   //保存字段 api类型、数据库类型共用
            //   fieldId: "", //字段id数据库类型使用 对应id
            //   fieldName: "",//字段id数据库类型使用 对应id
            // },
            conditionFields: [],
            //  {
            //   //条件字段 api类型、数据库类型共用
            //   fieldId: "", //字段id 数据库类型使用 对应id
            //   fieldName: "",//字段名称api类型使用 对应reqParams.paramName
            //   defaultValue: "", //默认值 api类型、数据库类型共用 对应resqarams.defaultValue
            //   inputValue: "", //录入值api类型、数据库类型共用 用户输入
            // },
            compareFields: [],
            //  {
            //   //比对字段api类型、数据库类型共用
            //   fieldId: "",//数据库类型使用 对应id
            //   fieldName: "", //api类型使用 对应resParams.paramName
            // },
  
            sortFields: [],
            //排序字段
          },
        },
        queryTypeList: [
          { label: "等于", value: "1", type: "0" },
          { label: "不等于", value: "2", type: "0" },
          { label: "区间", value: "3", type: "1" },
          { label: "大于", value: "4", type: "0" },
          { label: "小于", value: "5", type: "0" },
          { label: "大于等于", value: "6", type: "0" },
          { label: "小于等于", value: "7", type: "0" },
          { label: "包含", value: "8", type: "1" },
          { label: "不包含", value: "9", type: "1" },
        ],
        tableID: "",
        tableData: [],
        treeData: [],
        defaultProps: {
          children: "children",
          label: "name",
        },
        treeWay: "",
        treeWay2: "",
  
        SJKOP: [], //数据库下拉
        SBMOP: [], //数据库表名下拉
        APIOP: [], //api下拉
        SBZDbc: [], //表字段 保存
        SBZDtj: [], //表字段 条件
        SBZDdb: [], //表字段 对比
        SBZDpx: [], //排序字段
        CanEdit: false, //控制右侧操作
        tableTotal: 0,
        drawer: false, //预览
        page: {
          pageSize: PAGE_CONSTANT.TEN,
          currentPage: 1,
          total: 0,
        },
        tableLoading: false,
        gridRowBtn: [
          {
            label: this.$t("cip.cmn.btn.editBtn"),
            emit: "grid-edit",
            remark: "edit",
            type: "text",
            icon: "",
          },
          {
            label: this.$t("cip.dc.dataobject.title.tip2"),
            emit: "grid-see",
            remark: "see",
            type: "text",
            icon: "",
          },
  
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "grid-romve",
            remark: "remove",
            type: "text",
            icon: "",
          },
        ],
        headBtnOptions: [
          {
            label: this.$t("sinoma.btn.add"),
            emit: "head-add",
            type: "button",
            remark: "",
            icon: "",
          },
        ],
      };
    },
    mounted() {
      this.getdemotree();
    },
  
    methods: {
      MseeData(parms) {
        if (this.seetype == 2) {
          getPowerconsumption3({ pageNum: 1, pageSize: 100 }).then((res) => {
            this.tableData4List = Object.keys(res.data.data.data[0]);
            this.tableData4 = res.data.data.data;
          });
        } else {
          seeData(parms).then((res) => {
            this.tableData4 = res.data.data.list;
            this.tableData4List = res.data.data.titleCN;
            this.totalCount2 = res.data.data.total;
          });
        }
      },
  
      // 修改每页显示的条数
      handleSizeChange2(side) {
        // 改变每页显示的条数
        this.PageSize2 = side;
        // 注意：在改变每页显示的条数时，要将页码显示到第一页
        this.currentPage2 = 1;
  
        let parms = {
          id: this.rowId,
          query: {
            current: this.currentPage2,
            size: side,
          },
        };
  
        this.MseeData(parms);
      },
      // 显示第几页
      handleCurrentChange2(pageNumber) {
        // 改变默认的页数
        this.currentPage2 = pageNumber;
        let parms = {
          id: this.rowId,
          query: {
            current: this.currentPage2,
            size: this.PageSize2,
          },
        };
  
        this.MseeData(parms);
      },
      gettype(e) {
        if (e == 1) {
          return "数据库";
        }
        if (e == 2) {
          return "API接口";
        }
      },
      //编辑
      rowEdit(e, index) {
        this.$router.push({
          path: "/dataObject/add",
          query: {
            type: "edit",
            id: e.id,
            mid: e.modelId,
          },
        });
      },
      rowDel(row) {
        this.DelLsitid = row.id;
        this.dialogVisible3 = true;
      },
  
      toStr(str) {
        let data;
        if (str.tableComment) {
          data = str.tableComment;
        } else {
          data = str.tableName;
        }
        return decodeURI(data);
      },
      //树
      getdemotree() {
        getDataTree({ object: 0 }).then((res) => {
          if (res.status == 200) {
            this.treeData = res.data.data;
          }
        });
      },
  
      //数据对象列表
      getObjectList(e) {
        ObjectList(e).then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
  
          this.listAdd.dataObjectTypeId = e.dataObjectTypeId;
        });
      },
  
      opDialog(e) {
        if (e == 1) {
          this.dialogVisible1 = true;
  
          if (this.$refs.tree.getCheckedNodes().length == 0) {
            this.treeWay2 = "";
          }
        } else if (e == 2) {
          this.dialogVisible1 = true;
        } else if (e == 3) {
          if (this.$refs.tree.getCheckedKeys().length > 0) {
            this.dialogVisible2 = true;
          } else {
            this.$message({
              message: "请选择要删除的目录",
              type: "warning",
            });
          }
        } else if (e == 4) {
          // if (this.CanEdit) {
          if (this.ObjParms2.modelId) {
            // this.dialogVisible4 = true
            // this.AddTitle = "新建"
            this.$router.push({
              path: "/dataObject/add",
              query: {
                type: "add",
                id: e.id,
                mid: this.ObjParms2.modelId,
              },
            });
          } else {
            this.$message({
              message: "请选择左侧数据",
              type: "warning",
            });
          }
  
          // }
        } else if (e == 5) {
          // if (this.CanEdit) {
          if (
            this.$refs.multipleTable.selection.length > 0 &&
            this.$refs.multipleTable.selection.length < 2
          ) {
            this.dialogVisible3 = true;
          }
          if (this.$refs.multipleTable.selection.length == 0) {
            this.$message({
              message: this.$t("cip.dc.dataobject.msg.delmsg1"),
              type: "warning",
            });
          }
          if (this.$refs.multipleTable.selection.length > 1) {
            this.$message({
              message: this.$t("cip.dc.dataobject.msg.delmsg2"),
              type: "warning",
            });
          }
        }
  
        // }
      },
  
      Addclose() {
        this.addTreeData.parentId = "0";
        this.addTreeData.name = "";
        this.addTreeData.type = "0";
      },
  
      //点击树节点显示列表
      handleNodeClick(data) {
        this.ObjParms.dataObjectTypeId = data.id;
        this.ObjParms2.modelId = data.id;
        this.getObjectList(this.ObjParms2);
  
        var abb = JSON.parse(JSON.stringify(this.treeData));
        var alltree = this.flatten(abb);
        var cc = this.FinTfather(alltree, data.pid).reverse();
        this.treeWay = "";
        cc.forEach((item) => {
          this.treeWay += item + "/";
        });
        this.treeWay += data.name;
      },
  
      //扁平化 树
      flatten(arr) {
        let result = [];
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].children && arr[i].children.length > 0) {
            result = result.concat(this.flatten(arr[i].children));
            arr[i].children = [];
            result = result.concat(arr[i]);
          } else {
            result.push(arr[i]);
          }
        }
        return result;
      },
  
      //寻找父级
      FinTfather(arr, fid) {
        let arr2 = [];
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].id == fid) {
            if (arr[i].pid != "0") {
              fid = arr[i].pid;
              arr2.push(arr[i].name);
              this.FinTfather(arr, fid);
            } else {
              arr2.push(arr[i].name);
            }
          }
        }
        return arr2;
      },
  
      //选中树结构
      handleCheckChange(data) {
        console.log(this.$refs.tree.getCheckedKeys(), "ddddddddd");
  
        var abb = JSON.parse(JSON.stringify(this.treeData));
        var alltree = this.flatten(abb);
        var cc = this.FinTfather(alltree, data.parentId).reverse();
        this.treeWay2 = "";
        cc.forEach((item) => {
          this.treeWay2 += item + "/";
        });
        this.treeWay2 += data.name;
      },
      //树结构单选  储存id
      treeCheck(node, list) {
        if (this.$refs.tree.getCheckedNodes().length > 1) {
          this.$refs.tree.setCheckedKeys([node.id]);
        }
        this.ObjTreeid = node.id;
  
        this.addTreeData.parentId = node.id;
        if (this.$refs.tree.getCheckedNodes().length == 0) {
          this.addTreeData.parentId = "0";
        }
      },
  
      //新增树目录
      MAddTree() {
        // if (this.$refs.tree1.getCheckedNodes().length == 0) {
        if (this.$refs.tree.getCheckedNodes().length == 0) {
          this.addTreeData.parentId = undefined;
        }
        if (this.addTreeData.name) {
          if (!this.treeData || this.treeData.length == 0) {
            SaveAddTree(this.addTreeData).then((res) => {
              if (res.status == 200) {
                this.getdemotree();
                this.dialogVisible1 = false;
              }
            });
          }
  
          //  else if (this.$refs.tree1.getCheckedNodes().length>0) {
          else if (this.$refs.tree.getCheckedNodes().length > 0) {
            //  if(this.$refs.tree1.getCheckedNodes()[0].type != 1){
            if (this.$refs.tree.getCheckedNodes()[0].type != 1) {
              SaveAddTree(this.addTreeData).then((res) => {
                if (res.status == 200) {
                  this.getdemotree();
                  this.dialogVisible1 = false;
                }
              });
            } else {
              this.$message({
                message: "末级目录下不能新建目录",
                type: "warning",
              });
            }
          }
          // else if(this.$refs.tree1.getCheckedNodes().length==0){
          else if (this.$refs.tree.getCheckedNodes().length == 0) {
            SaveAddTree(this.addTreeData).then((res) => {
              if (res.status == 200) {
                this.getdemotree();
                this.dialogVisible1 = false;
              }
            });
          } else {
            this.$message({
              message: "末级目录下不能新建目录",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "请填写目录名称",
            type: "warning",
          });
        }
      },
      //删除树
      Mdeltree() {
        DelObjTree({ id: this.ObjTreeid }).then((res) => {
          if (res.status == 200) {
            this.getdemotree();
            this.dialogVisible2 = false;
          }
        });
      },
  
      //删除列表数据
      MDelObjectList() {
        DelObjectList({
          // id: this.$refs.multipleTable.selection[0].id
          id: this.DelLsitid,
        }).then((res) => {
          // this.getObjectList(this.ObjParms)
          this.getObjectList(this.ObjParms2);
          this.dialogVisible3 = false;
        });
      },
  
      //表格分页
  
      //size改变
      handleSizeChange(e) {
        this.page.pageSize = e.pageSize;
        this.ObjParms2.query.size = e.pageSize;
        console.log(this.ObjParms2, e);
        this.getObjectList(this.ObjParms2);
      },
      //页数改变
      handleCurrentChange(e) {
        this.page.currentPage = e.currentPage;
        this.ObjParms2.query.current = e.currentPage;
        this.getObjectList(this.ObjParms2);
      },
  
      //选择类型
      ChooseType(e) {
        this.SBZDbc = []; //表字段 保存
        this.SBZDtj = []; //表字段 条件
        this.SBZDdb = []; //表字段 对比
  
        //数据库
        if (e == 1) {
          this.listAdd.metadataInfoDTO.apiId = "";
          this.MgetSourcesList();
        }
        //api
        if (e == 2) {
          this.listAdd.metadataInfoDTO.metadataSourceId = "";
          this.listAdd.metadataInfoDTO.metadataTableId = "";
          getDataApisList({}).then((res) => {
            this.APIOP = res.data.data;
            console.log(this.SBZDbc, "ccccccccccc");
          });
        }
      },
  
      // 获取数据库
      MgetSourcesList() {
        // getSourcesList({}).then(res => {
        //   this.SJKOP = res.data.data
        // })
  
        getSourcesList2({ modelId: this.ObjParms2.modelId }).then((res) => {
          this.SJKOP = res.data.data;
        });
      },
  
      //选择数据库名后 获取表
      ChooseSJKOP(e) {
        // getSourcesTableList({ sourceId: e }).then(res => {
        //   this.SBMOP = res.data.data
        // })
        this.listAdd.metadataInfoDTO.metadataTableId = "";
        getSourcesTableList2({
          sourceId: e,
          modelId: this.ObjParms2.modelId,
        }).then((res) => {
          this.SBMOP = res.data.data;
        });
      },
      //选择表 后获取表字段
      ChooseSBMOP(e) {
        getColumnsList({
          sourceId: this.listAdd.metadataInfoDTO.metadataSourceId,
          tableId: e,
        }).then((res) => {
          console.log(res);
          this.SBZDbc = JSON.parse(JSON.stringify(res.data.data));
          // BCinput
          this.SBZDtj = JSON.parse(JSON.stringify(res.data.data));
          this.SBZDpx = JSON.parse(JSON.stringify(res.data.data));
          this.SBZDpx.forEach((item) => {
            this.$set(item, "sortRule", "");
          });
          this.SBZDtj.forEach((item) => {
            this.$set(item, "BCinput", "");
            this.$set(item, "TimeType", "");
            this.$set(item, "gdtime", "");
            this.$set(item, "DTime", "");
            this.$set(item, "QueryType", "");
            this.$set(item, "startValue", "");
            this.$set(item, "endValue", "");
            // this.$set(item, "inputValue", "")
          });
          this.SBZDdb = JSON.parse(JSON.stringify(res.data.data));
        });
      },
  
      //末级目录改颜色
      renderContent(h, { node, data, store }) {
        if (data.type == 1) {
          return <span style="font-size:14px;color:red">{node.label}</span>;
        } else {
          return <span style="font-size:14px">{node.label}</span>;
        }
      },
      //末级目录改颜色
      renderContent1(h, { node, data, store }) {
        if (data.type == 1) {
          return <span style="font-size:14px;color:red">{node.label}</span>;
        } else {
          return <span style="font-size:14px">{node.label}</span>;
        }
      },
  
      //选择api列表后
      ChooseAPIOP(e) {
        // this.listAdd.metadataInfoDTO.apiId = e
  
        getDataApisXQ(e).then((res) => {
          this.SBZDbc = JSON.parse(JSON.stringify(res.data.data.resParams));
          this.SBZDtj = JSON.parse(JSON.stringify(res.data.data.reqParams));
          this.SBZDtj.forEach((item) => {
            this.$set(item, "BCinput", "");
          });
          this.SBZDdb = JSON.parse(JSON.stringify(res.data.data.resParams));
        });
      },
  
      //新增数据对象 表格数据
      AddObjTable() {
        //数据库类型
        if (this.listAdd.sourceType == 1) {
          var aSBZDbcTable = this.$refs.SBZDbcTable.selection;
          var aSBZDdbTable = this.$refs.SBZDdbTable.selection;
          var aSBZDtjTable = this.$refs.SBZDtjTable.selection;
  
          var apxTable = this.$refs.pxTable.selection;
          this.listAdd.metadataInfoDTO.apiId = undefined;
          if (this.AddTitle == "新建") {
            this.listAdd.id = null;
          }
  
          //保存字段
          var a1 = [];
          aSBZDbcTable.forEach((item) => {
            a1.push({
              fieldId: item.id,
              // fieldName:item.columnName
            });
          });
          this.listAdd.metadataInfoDTO.saveFields = a1;
  
          //对比字段
          var a2 = [];
          aSBZDdbTable.forEach((item) => {
            a2.push({
              fieldId: item.id,
            });
          });
          this.listAdd.metadataInfoDTO.compareFields = a2;
  
          //条件字段
          var a3 = [];
          aSBZDtjTable.forEach((item) => {
            a3.push({
              fieldId: item.id,
              defaultValue: "",
              inputValue: item.BCinput,
              dateType: item.TimeType,
              beginTime: item.gdtime[0],
              endTime: item.gdtime[1],
              dynamicDateType: item.DTime,
              queryType: item.QueryType,
              startValue: item.startValue,
              endValue: item.endValue,
              // inputValue:item.inputValue,
            });
          });
  
          var a4 = [];
          apxTable.forEach((item) => {
            a4.push({
              fieldId: item.id,
              sortRule: item.sortRule,
            });
          });
  
          // dataType
          this.listAdd.metadataInfoDTO.conditionFields = a3;
          this.listAdd.metadataInfoDTO.sortFields = a4;
  
          this.listAdd.modelId = this.ObjParms2.modelId;
          this.listAdd.dataObjectTypeId = this.ObjParms2.modelId;
  
          let canAdd = true;
          a3.forEach((element) => {
            if (element.dateType) {
              if (element.dateType == 1) {
                if (!element.beginTime || !element.endTime) {
                  console.log("111111");
                  canAdd = false;
                  this.$message({
                    message: "请填写完整时间",
                    type: "warning",
                  });
                }
              }
              if (element.dateType == 2) {
                console.log("222222");
                if (!element.dynamicDateType) {
                  canAdd = false;
                  this.$message({
                    message: "请填写完整时间",
                    type: "warning",
                  });
                }
              }
            }
          });
  
          if (canAdd || a3.length == 0) {
            console.log("wwwwwwwwwwww");
            AddObjectList(this.listAdd).then((res) => {
              this.getObjectList(this.ObjParms2);
              this.dialogVisible4 = false;
            });
          }
  
          // if (a3.length == 0) {
          //   console.log("000000000000000");
          //   AddObjectList(this.listAdd).then(res => {
          //     this.getObjectList(this.ObjParms2)
          //     this.dialogVisible4 = false
          //   })
          // }
        }
  
        //api类型
        if (this.listAdd.sourceType == 2) {
          this.listAdd.metadataInfoDTO.metadataSourceId = undefined;
  
          if (this.AddTitle == "新建") {
            this.listAdd.id = null;
          }
  
          var bSBZDbcTable = this.$refs.SBZDbcTable2.selection;
          var bSBZDdbTable = this.$refs.SBZDdbTable2.selection;
          var bSBZDtjTable = this.$refs.SBZDtjTable2.selection;
  
          //保存字段
          var b1 = [];
          bSBZDbcTable.forEach((item) => {
            b1.push({
              fieldName: item.fieldName,
            });
          });
          this.listAdd.metadataInfoDTO.saveFields = b1;
  
          //对比字段
          var b2 = [];
          bSBZDdbTable.forEach((item) => {
            b2.push({
              fieldName: item.fieldName,
            });
          });
          this.listAdd.metadataInfoDTO.compareFields = b2;
  
          //条件字段
          var b3 = [];
          bSBZDtjTable.forEach((item) => {
            b3.push({
              // fieldId:item.id,
              fieldName: item.paramName,
              defaultValue: "",
              inputValue: item.BCinput,
            });
          });
          this.listAdd.metadataInfoDTO.conditionFields = b3;
          this.listAdd.modelId = this.ObjParms2.modelId;
          this.listAdd.dataObjectTypeId = this.ObjParms2.modelId;
  
          AddObjectList(this.listAdd).then((res) => {
            // this.getObjectList(this.ObjParms)
            this.getObjectList(this.ObjParms2);
            this.dialogVisible4 = false;
          });
        }
      },
      //预览数据对象
      SeeData(e) {
        console.log(e, "vvvvvvvvvvvvvvvvvvv");
        if (e.sourceType == 2) {
          this.seetype = 2;
          seeUrl.AiUrl = e.apiUrl;
          this.drawer = true;
  
          getPowerconsumption3({ pageNum: 1, pageSize: 100 }).then((res) => {
            // this.tableData4 = res.data.data.list
            this.tableData4List = Object.keys(res.data.data.data[0]);
            this.tableData4 = res.data.data.data;
          });
        } else {
          this.seetype = 1;
          this.drawer = true;
          this.rowId = e.id;
          seeData({ id: e.id, query: { current: 1, size: 50 } }).then((res) => {
            this.tableData4 = res.data.data.list;
            this.tableData4List = res.data.data.titleCN;
            this.totalCount2 = res.data.data.total;
          });
        }
      },
      closedrawer() {
        this.tableData4 = [];
        this.tableData4List = [];
      },
  
      //编辑数据对象
      EditList(e) {
        getDataOBJXQ(e.id).then((res) => {
          this.dialogVisible4 = true;
          this.AddTitle = "编辑";
          this.listAdd.id = e.id;
          console.log(res.data.data.code);
          this.listAdd.code = res.data.data.code;
          this.listAdd.label = res.data.data.label;
          this.listAdd.remark = res.data.data.remark;
          this.listAdd.sourceType = res.data.data.sourceType;
          this.listAdd.dataObjectTypeId = res.data.data.dataObjectTypeId;
  
          if (this.listAdd.sourceType == 1) {
            this.listAdd.metadataInfoDTO.metadataSourceId =
              res.data.data.metadataInfoDTO.metadataSourceId;
  
            this.listAdd.metadataInfoDTO.metadataTableId =
              res.data.data.metadataInfoDTO.metadataTableId;
  
            this.listAdd.metadataInfoDTO.saveFields =
              res.data.data.metadataInfoDTO.saveFields;
            this.listAdd.metadataInfoDTO.conditionFields =
              res.data.data.metadataInfoDTO.conditionFields;
            this.listAdd.metadataInfoDTO.compareFields =
              res.data.data.metadataInfoDTO.compareFields;
  
            this.MgetSourcesList();
            let cc = res.data.data.metadataInfoDTO.metadataSourceId;
            //    getSourcesTableList({ sourceId: cc }).then(res => {
            //   this.SBMOP = res.data.data
            // })
            getSourcesTableList2({
              sourceId: cc,
              modelId: this.ObjParms2.modelId,
            }).then((res) => {
              this.SBMOP = res.data.data;
            });
  
            getColumnsList({
              sourceId: cc,
              tableId: res.data.data.metadataInfoDTO.metadataTableId,
            }).then((res) => {
              this.SBZDbc = JSON.parse(JSON.stringify(res.data.data));
  
              console.log(
                this.SBZDbc,
                "bbbbbbbbb",
                this.listAdd.metadataInfoDTO.saveFields
              );
  
              this.$nextTick(() => {
                this.SBZDbc.forEach((item, index) => {
                  this.listAdd.metadataInfoDTO.saveFields.forEach((item1) => {
                    if (item.id == item1.fieldId) {
                      console.log("...............");
                      this.$refs.SBZDbcTable.toggleRowSelection(
                        this.SBZDbc[index],
                        true
                      );
                    }
                  });
                });
              });
  
              // BCinput
              this.SBZDtj = JSON.parse(JSON.stringify(res.data.data));
              this.SBZDtj.forEach((item) => {
                this.$set(item, "BCinput", "");
                this.$set(item, "TimeType", "");
                this.$set(item, "gdtime", "");
                this.$set(item, "DTime", "");
                this.$set(item, "QueryType", "");
                this.$set(item, "startValue", "");
                this.$set(item, "endValue", "");
                this.$set(item, "inputValue", "");
              });
  
              // inputValue
              this.$nextTick(() => {
                this.SBZDtj.forEach((item, index) => {
                  // this.listAdd.metadataInfoDTO.compareFields.forEach((item1)=>{
                  this.listAdd.metadataInfoDTO.conditionFields.forEach(
                    (item1) => {
                      if (item.id == item1.fieldId) {
                        console.log("...............");
                        this.$refs.SBZDtjTable.toggleRowSelection(
                          this.SBZDtj[index],
                          true
                        );
                        item.BCinput = item1.inputValue;
                      }
                    }
                  );
                });
              });
  
              this.SBZDdb = JSON.parse(JSON.stringify(res.data.data));
  
              this.$nextTick(() => {
                this.SBZDdb.forEach((item, index) => {
                  // this.listAdd.metadataInfoDTO.conditionFields.forEach((item1)=>{
                  this.listAdd.metadataInfoDTO.compareFields.forEach((item1) => {
                    if (item.id == item1.fieldId) {
                      console.log("...............");
                      this.$refs.SBZDdbTable.toggleRowSelection(
                        this.SBZDdb[index],
                        true
                      );
                    }
                  });
                });
              });
            });
          }
  
          if (this.listAdd.sourceType == 2) {
            this.listAdd.metadataInfoDTO.apiId =
              res.data.data.metadataInfoDTO.apiId;
  
            this.listAdd.metadataInfoDTO.saveFields =
              res.data.data.metadataInfoDTO.saveFields;
            this.listAdd.metadataInfoDTO.conditionFields =
              res.data.data.metadataInfoDTO.conditionFields;
            this.listAdd.metadataInfoDTO.compareFields =
              res.data.data.metadataInfoDTO.compareFields;
  
            getDataApisList({}).then((res) => {
              this.APIOP = res.data.data;
            });
  
            getDataApisXQ(res.data.data.metadataInfoDTO.apiId).then((res) => {
              this.SBZDbc = JSON.parse(JSON.stringify(res.data.data.resParams));
  
              this.$nextTick(() => {
                this.SBZDbc.forEach((item, index) => {
                  this.listAdd.metadataInfoDTO.saveFields.forEach((item1) => {
                    if (item.fieldName == item1.fieldName) {
                      console.log("...............");
                      this.$refs.SBZDbcTable2.toggleRowSelection(
                        this.SBZDbc[index],
                        true
                      );
                    }
                  });
                });
              });
  
              this.SBZDtj = JSON.parse(JSON.stringify(res.data.data.reqParams));
  
              this.SBZDtj.forEach((item) => {
                this.$set(item, "BCinput", "");
              });
  
              this.$nextTick(() => {
                this.SBZDtj.forEach((item, index) => {
                  this.listAdd.metadataInfoDTO.conditionFields.forEach(
                    (item1) => {
                      console.log(item, ".......222........", item1);
                      if (item.paramName == item1.fieldName) {
                        this.$refs.SBZDtjTable2.toggleRowSelection(
                          this.SBZDtj[index],
                          true
                        );
                        item.BCinput = item1.inputValue;
                      }
                    }
                  );
                });
              });
  
              this.SBZDdb = JSON.parse(JSON.stringify(res.data.data.resParams));
  
              this.$nextTick(() => {
                this.SBZDdb.forEach((item, index) => {
                  this.listAdd.metadataInfoDTO.compareFields.forEach((item1) => {
                    if (item.fieldName == item1.fieldName) {
                      console.log("...............");
                      this.$refs.SBZDdbTable2.toggleRowSelection(
                        this.SBZDdb[index],
                        true
                      );
                    }
                  });
                });
              });
            });
          }
  
          // this.listAdd.label = res.data.data.label
          // this.listAdd.label = res.data.data.label
        });
      },
  
      //关闭新增数据弹框时
      Addclose2() {
        for (let key in this.listAdd) {
          if (key != "dataObjectTypeId") {
            this.listAdd[key] = "";
          }
        }
        this.listAdd.metadataInfoDTO = {
          apiId: "",
          metadataSourceId: "",
          metadataTableId: "",
          saveFields: [],
          conditionFields: [],
          compareFields: [],
        };
      },
  
      //打开新增时清空数据
      Addopen2() {
        if (this.AddTitle == "新建") {
          this.SBZDbc = [];
          this.SBZDtj = [];
          this.SBZDdb = [];
        }
      },
  
      //时间类型
      changeTimeType(e, a) {
        if (e == 1) {
          a.gdtime = "";
        }
        if (e == 2) {
          a.DTime = "";
          getDTime({
            dict_code: "dynamic_data_type",
          }).then((res) => {
            this.DtimeType = res.data.data;
          });
        }
      },
      changeQueryType(e, a) {
        a.BCinput = "";
        a.startValue = "";
        a.endValue = "";
      },
    },
  };
  </script>
  <style lang='less' scoped>
  .btbox {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
  }
  
  .dialog-footer1 {
    display: flex;
    justify-content: flex-end;
  }
  ::v-deep .avue-crud .el-table {
    height: calc(100vh - 218px) !important;
    max-height: calc(100vh - 218px) !important;
  }
  .show_table {
    position: relative;
    width: 100%;
    height: 580px; //高度加到这里
    overflow: auto;
  }
  .paging {
    float: right;
    margin-top: 10px;
  }
  </style>
  